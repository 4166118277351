// Add highlighter flourish to carousel details
@media (min-width: map-get($breakpoints, lg)) {
    .carouselSlideHeading {
      margin-bottom: 0;
    }
    .carouselSlideSummary {
      margin-top:  0.25em;
    }
}
// Force bold weight for strong in home intro
.homeIntro strong {
    font-weight: bold;
}

// Useful mixin for before and after
@mixin beforeafter {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

// 608086 Remove the curve at the bottom of their carousel / post banner images
// // Add curve to carousel
// .carouselSlide {
//     &:after {
//         @include beforeafter;
//         height: calc((73 / 1440) * 100vw);
//         bottom: -1px;
//         background-image: url($assets-path + "carousel-curve.svg");
//         background-size: 101%;
//     }
// }

// Add curve to stats
.homefeaturecategory-homeboximpactstats {
    &:after {
        @include beforeafter;
        height: calc((73 / 1440) * 100vw);
        top: -2px;
        background-image: url($assets-path + "carousel-curve.svg");
        background-size: 101%;
        transform: rotate(180deg);
    }
}
// Add curve to footer
.pageFooterWrapper {
    position: relative;
    margin-top: $spacer * 8;
    &:after {
        @include beforeafter;
        height: calc((73 / 1440) * 100vw);
        top: calc(((-73 / 1440) * 100vw) + 2px);
        background-image: url($assets-path + "footer-curve.svg");
        background-size: 101%;
        transform: scaleX(-1);
    }
}
.homepage {
    .pageFooterWrapper {
        margin-top: 0;
    }
    .homefeaturecategory-homeboxquickgiving {
        padding-bottom: 8rem;
    }
}
.pageFooter {
    padding-top: 0;
}
// Force summary to display on text over image cards
.homeFeatures .homeFeature [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
    justify-content: center;
    h2 {
        position: relative;
        text-shadow: 0 0 20px rgba(black, 0.5);
    }
    >p {
        opacity: 1;
        position: relative;
        padding-top: 0;
        text-shadow: 0 0 20px rgba(black, 0.5);
    }
}
// Maintain aspect ratio on side-by-side feeds images
.homeFeedBox2 .feedItem [class*="image-link"] {
    height: auto;
    align-self: start;
    img {
        height: auto;
    }
}
.homeFeedBox2 .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
    padding-bottom: 0;
}

.footerBox {
    font-size: 1rem;
}
// Add line top of main nav
@media (min-width: map-get($breakpoints, sm)) {
    #bodyForm {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 1px;
            background-color: rgba(black, 0.1);
        }
    }
}

.pageFooter {
    padding-bottom: 0;
}
.footerBox.footerBox4 {
    grid-column: 1 / span 16;
    background-color: white;
    margin: $spacer * 2 0 0 0;
    padding: $spacer * 2;
    display: flex;
    justify-content: center;
    img {
        height: 80px;
        width: auto;
        margin: 0 $spacer * 2;
    }
    @media (max-width: map-get($breakpoints, lg)) {
        display: block;
        img {
            margin: $spacer * 2 auto;
            display: block;
        }
    }
}

@media (max-width: map-get($breakpoints, lg)) {
    .carouselSlideDetail {
        padding-bottom: 3rem;
    }
}
// Home icon in main nav
.menuMain .topLevel.level1 a[href='/'] {
    font-size: 0;
    &:before {
        content:'\f015';
        font-family: 'FontAwesome', icon;
        font-size: 1.375rem;
        font-weight: normal;
        line-height: 1.2;
    }
}
